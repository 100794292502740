// sidebar button not woeking
/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector(".heart");
    // function heartBlast() {
    //   return handleheartBlast.classList.toggle("heart-blast");
    // }
    // handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;

  /// Active menu

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li
            className={path === "/dashboard" || path === "/" ? "mm-active" : ""}
          >
            <Link to="/dashboard">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={path === "/DataFiltering" ? "mm-active" : ""}>
            <Link to="/DataFiltering">
              <i className="fa fa-tv"></i>
              <span className="nav-text">Data Management</span>
            </Link>
          </li>
          <li className={path === "/bank" ? "mm-active" : ""}>
            <Link to="/bank">
              <i className="fa fa-university"></i>
              <span className="nav-text">Bank</span>
            </Link>
          </li>
          <li className={path === "/Data_report" ? "mm-active" : ""}>
            <Link to="/Data_report">
              <i className="fa fa-file"></i>
              <span className="nav-text">Data & report</span>
            </Link>
          </li>
          <li className={path === "/Financal_News" ? "mm-active" : ""}>
            <Link to="/Financal_News">
              <i className="fa fa-newspaper"></i>
              <span className="nav-text">Financal News</span>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
