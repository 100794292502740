/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const DataFiltering = () => {
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },

    {
      Header: "Bank Name",
      Footer: "Bank Name",
      accessor: "bank_id",
      Filter: ColumnFilter,
    },
    {
      Header: "Branch",
      Footer: "Branch",
      accessor: "branch",
      Filter: ColumnFilter,
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "date",
      Filter: ColumnFilter,
    },

    {
      Header: "Establish Year",
      Footer: "establish_year",
      accessor: "establish_year",
      Filter: ColumnFilter,
    },
    {
      Header: "Establish in mill",
      Footer: "equity_in_mill",
      accessor: "equity_in_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Loan mill",
      Footer: "loan_mill",
      accessor: "loan_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Total assets mill",
      Footer: "totassets_mill",
      accessor: "totassets_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Deposit mill",
      Footer: "deposit_mill",
      accessor: "deposit_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Profit before tax mill",
      Footer: "profit_before_tax_mill",
      accessor: "profit_before_tax_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Interest exp mill",
      Footer: "interest_exp_mill",
      accessor: "interest_exp_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Non Interest exp mill",
      Footer: "non_interest_exp_mill",
      accessor: "non_interest_exp_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Net income mill",
      Footer: "net_income_mill",
      accessor: "net_income_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Interest income mill",
      Footer: "interest_income_mill",
      accessor: "interest_income_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Net Interest income mill",
      Footer: "net_interest_income_mill",
      accessor: "net_interest_income_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Net operating revenue mill",
      Footer: "net_operating_revenue_mill",
      accessor: "net_operating_revenue_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Non performing loan mill",
      Footer: "non_performing_loan_mill",
      accessor: "non_performing_loan_mill",
      Filter: ColumnFilter,
    },
    {
      Header: "Loan to deposit",
      Footer: "loan_to_deposit",
      accessor: "loan_to_deposit",
      Filter: ColumnFilter,
    },
    {
      Header: "ROA",
      Footer: "roa",
      accessor: "roa",
      Filter: ColumnFilter,
    },
    {
      Header: "ROE",
      Footer: "roe",
      accessor: "roe",
      Filter: ColumnFilter,
    },
    {
      Header: "Profit margin",
      Footer: "profit_margin",
      accessor: "profit_margin",
      Filter: ColumnFilter,
    },
    {
      Header: "Yield",
      Footer: "yield",
      accessor: "yield",
      Filter: ColumnFilter,
    },
    {
      Header: "Asset utilization",
      Footer: "asset_utilization",
      accessor: "asset_utilization",
      Filter: ColumnFilter,
    },
    {
      Header: "Efficiency",
      Footer: "efficiency",
      accessor: "efficiency",
      Filter: ColumnFilter,
    },
    {
      Header: "Net interest margin",
      Footer: "net_interest_margin",
      accessor: "net_interest_margin",
      Filter: ColumnFilter,
    },
    {
      Header: "Overhead ratio",
      Footer: "overhead_ratio",
      accessor: "overhead_ratio",
      Filter: ColumnFilter,
    },
    {
      Header: "Asset growth rate",
      Footer: "asset_growth_rate",
      accessor: "asset_growth_rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Equity growth rate",
      Footer: "equity_growth_rate",
      accessor: "equity_growth_rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Deposit growth rate",
      Footer: "deposit_growth_rate",
      accessor: "deposit_growth_rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Loan growth rate",
      Footer: "loan_growth_rate",
      accessor: "loan_growth_rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Net income growth rate",
      Footer: "net_income_growth_rate",
      accessor: "net_income_growth_rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Non Interset income growth rate",
      Footer: "non_interest_exp_growth_rate",
      accessor: "non_interest_exp_growth_rate",
      Filter: ColumnFilter,
    },
    {
      Header: "Action",
      Footer: "Action",

      Cell: ({ row }) => {
        console.log(row.original.id, "company_details");
        const changeStatus = (id) => {
          setLoader(true);
          var config = {
            method: "delete",
            url: `${Baseurl.baseurl}record/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response?.data?.status === true) {
                setLoader(false);
                Swal.fire({
                  showCloseButton: true,

                  toast: true,
                  icon: "success",
                  title: response?.data?.message,
                  animation: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });
                GetCompanies();
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const [checkStatus, setCheckStatus] = useState("");
        return (
          <>
            <button
              onClick={() => changeStatus(row.original.id)}
              className={`btn btn-outline-danger`}
            >
              <i className="fa fa-trash" />
            </button>
          </>
        );
      },
      Filter: ColumnFilter,
    },
  ]; //   const data = useMemo(() => MOCK_DATA, []);
  const [columns] = useState(COLUMNS);
  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const token = useSelector((state) => state.auth.auth.idToken);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bankid, setBankid] = useState("");
  const [Bank, setBank] = useState([]);
  const [resetButton, setResetButton] = useState(false);
  const handleChange = (setState) => (e) => setState(e.target.value);
  const [showData, setShowData] = useState(false);
  const [show, setShow] = useState(false);
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  console.log(resetButton, "reset");
  const GetCompanies = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}record`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          Swal.fire({
            showCloseButton: true,
            toast: true,
            icon: "success",
            title: response?.data?.message,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setData(response?.data.data);
          setLoader(false);
          setResetButton(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const getFilter = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}record?startDate=${startDate}&endDate${endDate}=&bank_id=${bankid}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data, "Getting Compaines");
        setData(response?.data.data);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setData(response?.data.data);
          setLoader(false);
          setShow(false);
          setResetButton(true);
          setLoader(false);
        } else {
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const getBank = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}bank`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data, "Getting Compaines");
        if (response.status === 200) {
          setBank(response?.data.data);
        } else {
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  useEffect(() => {
    GetCompanies();
    getBank();
  }, []);
  const Api_Data = [
    { name: "branch", state: "branch" },
    { name: "date", state: "date" },
    { name: "establish_year", state: "establish_year" },
    { name: "equity_in_mill", state: "equity_in_mill" },
    { name: "loan_mill", state: "loan_mill" },
    { name: "totassets_mill", state: "totassets_mill" },
    { name: "deposit_mill", state: "deposit_mill" },
    { name: "profit_before_tax_mill", state: "profit_before_tax_mill" },
    { name: "interest_exp_mill", state: "interest_exp_mill" },
    { name: "non_interest_exp_mill", state: "non_interest_exp_mill" },
    { name: "net_income_mill", state: "net_income_mill" },
    { name: "interest_income_mill", state: "interest_income_mill" },
    { name: "net_interest_income_mill", state: "net_interest_income_mill" },
    { name: "net_operating_revenue_mill", state: "net_operating_revenue_mill" },
    { name: "non_performing_loan_mill", state: "non_performing_loan_mill" },
    { name: "loan_to_deposit", state: "loan_to_deposit" },
    { name: "roa", state: "roa" },
    { name: "roe", state: "roe" },
    { name: "profit_margin", state: "profit_margin" },
    { name: "yield", state: "yield" },
    { name: "asset_utilization", state: "asset_utilization" },
    { name: "efficiency", state: "efficiency" },
    { name: "net_interest_margin", state: "net_interest_margin" },
    { name: "overhead_ratio", state: "overhead_ratio" },
    { name: "asset_growth_rate", state: "asset_growth_rate" },
    { name: "equity_growth_rate", state: "equity_growth_rate" },
    { name: "deposit_growth_rate", state: "deposit_growth_rate" },
    { name: "laon_growth_rate", state: "laon_growth_rate" },
    { name: "net_income_growth_rate", state: "net_income_growth_rate" },
    {
      name: "non_interest_exp_growth_rate",
      state: "non_interest_exp_growth_rate",
    },
    {
      name: "non_interest_exp_growth_rate",
      state: "non_interest_exp_growth_rate",
    },
  ];
  const initialState = Api_Data.reduce((acc, { name, state }) => {
    acc[state] = ""; // Set initial state value as empty string, you can change it as needed
    return acc;
  }, {});

  const [api, setApi] = useState(initialState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setApi((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };
      console.log(updatedState); // Console log the updated state
      return updatedState;
    });
  };

  const AddData = () => {
    setLoader(true);
    const fromdata = new FormData();
    Api_Data?.map((e) => fromdata.append(e.name, api[e.state]));
    fromdata.append("bank_id", bankid);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}record`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data, "Getting Compaines");
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          GetCompanies();
          setShowData(false);
        } else {
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const [xlsx, setXlsx] = useState("");
  const AddExecl = () => {
    setLoader(true);
    const fromdata = new FormData();

    fromdata.append("file", xlsx[0]);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}import/record`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data, "Getting Compaines");
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          GetCompanies();
          setShowData(false);
        } else {
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const [manullay, setManullay] = useState(false);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Data Management</h4>

            {resetButton ? (
              <>
                <div className="float-right">
                  <button
                    className="btn btn-outline-danger float-right"
                    onClick={() => {
                      GetCompanies();
                    }}
                  >
                    Reset filter <i className="fa fa-repeat" />
                  </button>

                  <ReactHTMLTableToExcel
                    id="exportButton"
                    className="export-button btn btn-primary ms-2"
                    table="table-to-export"
                    filename="EthioFinance"
                    sheet="sheet1"
                    buttonText="Export to Excel"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="float-right">
                  <button className="btn btn-primary ms-2" onClick={onShow}>
                    Filteration <i className="fa fa-filter" />
                  </button>

                  <button className="btn btn-primary ms-2" onClick={onShowData}>
                    Add Data <i className="fa fa-plus" />
                  </button>

                  <ReactHTMLTableToExcel
                    id="exportButton"
                    className="export-button btn btn-primary ms-2"
                    table="table-to-export"
                    filename="EthioFinance "
                    sheet="sheet1"
                    buttonText="Export to Excel"
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <ModalHeader>Filterration</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-3">
                <label htmlFor=""> Bank Name</label>
                <select
                  onChange={handleChange(setBankid)}
                  className="form-control"
                >
                  <option value="" selected disabled hidden>
                    Select Your Bank Name
                  </option>
                  {Bank?.map((e) => (
                    <option value={e?.id}>{e?.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="">Start Date</label>
                <input
                  type="date"
                  placeholder="Start Date"
                  className="form-control mb-3"
                  value={startDate}
                  onChange={handleChange(setStartDate)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">End Date</label>

                <input
                  type="date"
                  placeholder="end Date"
                  className="form-control mb-3"
                  value={endDate}
                  onChange={handleChange(setEndDate)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHide}>
            Close
          </div>

          <div className="btn btn-outline-success" onClick={getFilter}>
            Sumbit
          </div>
        </ModalFooter>
      </Modal>
      <Modal show={showData} onHide={() => setShowData(false)} size="lg">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">
                  {manullay ? "Import Data " : "Add Data Manullay "}
                </h4>
              </div>
              <div className="col-md-6">
                <div className="float-right">
                  <button
                    className=" btn btn-outline-primary w-100"
                    onClick={() => {
                      setManullay(!manullay);
                    }}
                  >
                    {manullay ? "Add Data" : "Import Data"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {!manullay ? (
                <>
                  <div className="col-md-12 mb-3">
                    <label htmlFor=""> Bank Name</label>
                    <select
                      onChange={handleChange(setBankid)}
                      className="form-control"
                    >
                      <option value="" selected disabled hidden>
                        Select Your Bank Name
                      </option>
                      {Bank.map((e) => (
                        <option value={e?.value}>{e?.label}</option>
                      ))}
                    </select>
                  </div>
                  {Api_Data?.map((e) => {
                    return (
                      <>
                        <div className="col-md-6 mb-3">
                          <label htmlFor={e.state} className="text-capitalize">
                            {e.name.replace(/_/g, " ")}
                          </label>{" "}
                          <input
                            key={e.name}
                            type={e.state === "date" ? "date" : "number"}
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          />
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <label htmlFor="">Upload Excel File</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setXlsx(e.target.files)}
                  />

                  <p className="mt-2">
                    If You Dont have Sample excel File,{" "}
                    <a
                      href="/SampleFile.xlsx"
                      download={"Sample File"}
                      className="text-primary fw-bold "
                    >
                      <i>click here to download it.</i>
                    </a>
                  </p>
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            Close
          </div>

          <div
            className="btn btn-outline-success"
            onClick={!manullay ? AddData : AddExecl}
          >
            Sumbit
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default DataFiltering;
